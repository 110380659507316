@import "../styles/variables";
@import "../styles/responsive";

.header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: white;
  box-shadow: inset 0px -1px 0px $shadow;
  position: relative;

  & > .logo {
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;

    @media (max-width: 500px) {
      left: 30%;
    }
  }

  .burger {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
    -webkit-transition-delay: 1s;
    transition-delay: 1s;
    width: 20px;
    height: 13px;
    z-index: 100;
  }

  .burger:hover span:nth-child(3) {
    width: 20px;
  }

  .burger span {
    width: 20px;
    height: 1px;
    display: block;
    background-color: black;
    position: relative;
  }

  .burger span:nth-child(1),
  .burger span:nth-child(2) {
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }

  .burger span:nth-child(3) {
    width: 13px;
    -webkit-transition: width 0.5s, opacity 1s;
    transition: width 0.5s, opacity 1s;
    opacity: 1;
  }

  .burgerAnimation span {
    position: absolute;
  }

  .burgerAnimation span:nth-child(1) {
    -webkit-transform: rotate(45deg) translate3d(6px, 6px, 0);
    transform: rotate(45deg) translate3d(6px, 6px, 0);
  }

  .burgerAnimation span:nth-child(2) {
    -webkit-transform: rotate(-45deg) translate3d(-6px, 6px, 0);
    transform: rotate(-45deg) translate3d(-6px, 6px, 0);
  }

  .burgerAnimation span:nth-child(3) {
    opacity: 0;
    -webkit-transition: opacity 0.01s;
    transition: opacity 0.01s;
  }

  .tools {
    display: flex;
    align-items: center;
    min-width: 110px;

    & > div {
      border-right: 1px solid $divider;
      padding: 0 10px;

      &:last-of-type {
        border: none;
      }
    }

    .userInfo {
      position: relative;

      &__icon {
        cursor: pointer;

        span {
          position: absolute;
          height: 5px;
          width: 5px;
          border-radius: 5px;
          background-color: rgb(115, 115, 234);
          top: 0;
          right: -3px;
        }
      }
    }

    .cart {
      position: relative;
      cursor: pointer;

      &Window {
        position: absolute;
        background: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        z-index: 5;
        width: 350px;
        padding: 24px 24px;
        font-size: 20px;
        margin-top: 10px;
        right: 0;
        top: 40px;
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.12);
        border-radius: 12px;
        -webkit-border-radius: 12px;
        visibility: hidden;

        p {
          font-size: 20px;
          line-height: 16px;
        }

        a:hover {
          color: $primary;
        }
      }

      .visible {
        visibility: visible;
      }
    }

    .languageDropdown {
      position: relative;

      .languageSelector {
        & span {
          text-transform: uppercase;
        }
      }

      #language {
        position: absolute;
      }

      .language_list {
        display: none;
        position: absolute;
        top: 20px;
        left: 0;
      }

      .visible {
        visibility: visible;
        display: inherit;
      }

      .rotateArrow {
        transition: ease all 0.3s;
        margin: 0 0 2px 10px;
        height: 8px;
        width: 13px;
        transform: rotateZ(180deg);
      }

      & > button {
        background-color: transparent;
        border: none;
        font-family: 'DBAdmanX', sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 16px;
        color: #303133;
        cursor: pointer;
        letter-spacing: 0.05em;

        & .arrow {
          margin: 0 0 2px 10px;
          height: 8px;
          width: 13px;
          transition: ease all 0.3s;
        }
      }

      ul {
        border: 1px solid rgb(237, 237, 237);
        border-radius: 0.5rem;
        overflow: hidden;
        background-color: white;
        margin-top: 8px;
        list-style-type: none;
      }

      li > button {
        background-color: transparent;
        border: none;
        font-size: 14px;
        font-family: 'DBAdmanX', sans-serif;
        width: 58px;
        text-align: left;
        padding: 6px;
        cursor: pointer;
        letter-spacing: 0.05em;

        &:hover {
          background-color: #5897fb;
          color: white;
        }

        &:active {
          background-color: gray;
        }
      }
    }
  }

  .userInfoWindow {
    position: absolute;
    background: white;
    z-index: 5;
    width: 200px;
    padding: 24px 24px;
    font-size: 20px;
    margin-top: 10px;
    right: 0;
    top: 40px;
    width: 200px;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    visibility: hidden;

    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    li {
      list-style-type: none;

      .memberPlatform {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;

        &:hover {

          .txt {
            color: $primary;
          }
        }
      }

      a {
        text-decoration: none;

        &:hover {
          color: $primary;
        }
      }

      span {
        color: black;
      }
    }
  }

  .navigation {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    visibility: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 12vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: auto;

    @include media(mobile) {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 30px;
    }
  }

  .navigation__main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 40px;
    line-height: 40px;
    padding: 35px 0;
    max-width: 300px;
    border-bottom: 1px solid #bfbfbf;

    @include media(mobile) {
      font-size: 28px;
      width: 100%;
      max-width: inherit;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 30px;
    }

    a {
      text-decoration: none;
    }
  }

  .navigation__main li {
    list-style-type: none;
    padding: 15px 0;

    @include media(mobile) {
      padding: 0;
    }
  }

  .navigation__main li .navLink {
    list-style-type: none;
    color: #bfbfbf;
  }

  .navigation__main li .navLink:hover {
    list-style-type: none;
    opacity: 0.7;
  }

  .navigation__actions {
    list-style-type: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 15px;
    max-width: 600px;
    margin-top: 35px;

    @include media(mobile) {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 10px;
    }

    a {
      text-decoration: none;
    }
  }

  .icon {

  }

  .navigation__actions a {
    font-size: 18px;
    font-weight: 900;
    line-height: 20px;
    letter-spacing: 0.05em;
    margin-bottom: 8px;
    text-transform: uppercase;
    color: black;
    display: inline-block;
    border: 1px solid #cdcdcd;
    padding: 5px 5px 5px 5px;
    border-radius: 5px;

    @include media(mobile) {
      font-size: 16px;
    }
  }

  .visible {
    visibility: visible;
    display: inherit;
  }
}

.overlay {
  position: absolute;
  top: 60px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
  visibility: hidden;
}

.visible {
  visibility: visible;
}
