@import "src/styles/variables";
@import "src/styles/responsive";

.home {
  margin: 64px 240px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @include media(desktop) {
    margin: 64px 104px;
  }
  @include media(landscape) {
    margin: 64px 80px;
  }
  @include media(tablet) {
    margin: 64px 100px;
  }
  @include media(mobile) {
    margin: 64px 20px;
  }

  .about {
    display: flex;
    justify-content: space-between;
    gap: 80px;
    align-items: center;

    @include media(tablet) {
        flex-direction: column;
        justify-content: center;
    }
    @include media(mobile) {
        flex-direction: column;
        justify-content: center;
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 16px;

      @include media(tablet) {
        align-items: center;
        text-align: center;
      }
      @include media(mobile) {
        align-items: center;
        text-align: center;
      }

      .support {
        padding: 12px 24px;
        background: $blue-background;
        color: $primary;
        border-radius: 40px;
        border: none;
        width: fit-content;
        margin-bottom: 8px;
        font-family: 'DBAdmanX', sans-serif;
        font-weight: 700;
        font-size: 28px;
        line-height: 24px;
      }

      .description {
        font-size: 20px;
        line-height: 28px;
      }
    }

    .picture {

      img {
        width: 711px;
        max-width: 711px;
        height: 410px;

        @include media(mobile) {
          max-width: 346px;
          height: 200px;
        }
      }
    }
  }

  .filters {
    display: flex;
    border-bottom: 1px solid $grey-line;
    width: 100%;

    .year {
      cursor: pointer;
      padding: 15px;
      border: none;
      background: none;
      text-decoration: none;

      font-size: 24px;
      line-height: 24px;
    }

    .selected {
      transition: ease all 0.3s;
      border-bottom: 1px solid $primary;
    }
  }

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;

    @include media(tablet){
      grid-template-columns: 1fr;
      grid-gap: 48px;
    }
    @include media(mobile){
      grid-template-columns: 1fr;
      grid-gap: 48px;
    }

    a {
      text-decoration: none;
    }
  }
}