@import "src/styles/variables";
@import "src/styles/responsive";

.card {
  display: flex;
  gap: 32px;
  transition: ease all 0.3s;
  padding-right: 20px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 10px 20px $shadow;
  }

  .content {
    display: flex;
    gap: 32px;

    @include media(mobile) {
      display: inline-table;
      width: 60vw;
      height: 30vw;
    }


    .picture {
      min-width: 220px;
      height: 160px;
      background: $common-background;
      align-items: center;
      display: flex;

      @include media(mobile) {
        min-width: 60vw;
        height: 30vw;
        margin-bottom: 24px;
      }

      img {
        border-radius: 8px;
        object-fit: cover;
        width: 100%;
        object-position: center;
        height: 100%;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .year {
        display: inherit;
        padding: 4px 8px;
        background: $blue-background;
        color: $primary;
        border-radius: 40px;
        border: none;
        width: fit-content;
        margin-bottom: 4px;

        font-weight: 700;
        font-size: 20px;
        line-height: 20px;

        @include media(mobile) {
          display: none;
        }
      }

      .title {
        color: $text-main;
        font-weight: 700;
        font-size: 24px;
        line-height: 26px;
      }

      .description {

      }
    }
  }

  .year {
    display: none;
    padding: 4px 8px;
    background: $blue-background;
    color: $primary;
    border-radius: 6px;
    border: none;
    height: fit-content;
    text-align: center;
    margin-bottom: 4px;

    font-weight: 700;
    font-size: 20px;
    line-height: 20px;

    @include media(mobile) {
      display: inherit;
    }
  }
}