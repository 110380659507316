$white: #ffffff;
$black: #303133;
$text-main: #262C31;
$grey: #9C9C9C;
$grey-secondary: #97A3AE;
$primary: #399DF3;

$divider: #E7E7E7;
$grey-line: #E5E5E5;

$blue-background: rgba(57, 157, 243, 0.07);
$common-background: #F8F8F8;

$shadow: #ECECEC;