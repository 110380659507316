@import "../styles/responsive";
@import "../styles/variables";

.footer {
  background-image: url("data:image/svg+xml,%3Csvg width='1920' height='580' viewBox='0 0 1920 580' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 572.321C0 572.321 564.667 548.07 962 548.07C1359.33 548.07 1920 580 1920 580V9.53674e-06C1920 9.53674e-06 1516 42.0349 962 42.0349C408 42.0349 0 9.53674e-06 0 9.53674e-06V572.321Z' fill='%23F8F8F8'/%3E%3C/svg%3E%0A");
  background-size: cover;
  background-position-x: center;
  background-repeat: no-repeat;
  margin-top: 140px;
  position: relative;
  @include media(landscape) {
    background-image: none;
    background-color: #F8F8F8;
  }
  @include media(tablet) {
    background-image: none;
    background-color: #F8F8F8;
  }
  @include media(mobile) {
    background-image: none;
    background-color: #F8F8F8;
    margin-top: 80px;
  }

  .footer_content {
    width: 942px;
    margin: 0 auto;
    padding: 106px 20px 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media(landscape) {
      width: 100%;
    }
    @include media(tablet) {
      width: 100%;
    }
    @include media(mobile) {
      width: 100%;
      padding: 32px 20px 0 20px;
    }

    &__navigation {

      & > img {
        display: block;
        margin: 0 auto;
        cursor: pointer;
      }

      & > hr {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid #E7E7E7;
        margin: 24px auto 24px auto;
        padding: 0;
        width: 600px;

        @include media(mobile) {
          display: none;
        }
      }

      & > nav {
        display: flex;
        justify-content: center;
        @include media(mobile) {
          display: none;
        }

        & a {
          font-family: DBAdmanX, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          letter-spacing: 1px;
          color: #97A3AE;
          text-decoration: none;
          padding: 0px 24px;
          white-space: nowrap;
          position: relative;
          z-index: 1;
          cursor: pointer;

          &:hover {
            color: $primary;
          }
        }


      }

    }

    &__info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-top: 52px;
      column-gap: 64px;
      @include media(landscape) {
        grid-template-columns: 1fr;
        margin-top: 64px;
      }
      @include media(tablet) {
        grid-template-columns: 1fr;
        margin-top: 64px;
      }
      @include media(mobile) {
        grid-template-columns: 1fr;
        margin-top: 40px;
      }

      & h3 {
        font-family: DBAdmanX, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.6px;
        text-transform: capitalize;
        color: #303133;
        @include media(landscape) {
          text-align: center;
        }
        @include media(tablet) {
          text-align: center;
        }
        @include media(mobile) {
          text-align: center;
        }
      }

      .contact_us {
        position: relative;
        z-index: 1;

        & > ul {
          margin-top: 38px;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(2, 1fr);
          row-gap: 40px;
          column-gap: 32px;
          @include media(landscape) {
            display: flex;
            justify-content: center;
          }
          @include media(tablet) {
            display: flex;
            justify-content: center;
          }
          @include media(mobile) {
            display: flex;
            flex-wrap: wrap;
            text-align: center;
            column-gap: 0;
          }

          & li {
            display: flex;
            justify-content: center;

            @include media(mobile) {
              flex: 1 1 100px
            }
          }

          & li > a {
            display: flex;
            text-decoration: none;

            @include media(landscape) {
              flex-direction: column;
              align-items: flex-start;
              & > img {
                margin-bottom: 8px;
              }
            }

            @include media(tablet) {
              flex-direction: column;
              align-items: flex-start;
              & > img {
                margin-bottom: 8px;
              }
            }

            @include media(mobile) {
              flex-direction: column;
              align-items: center;

              & > img {
                margin-bottom: 8px;
              }
            }
          }

          & li img {
            margin-right: 8px;
            height: 20px;
            width: 20px;
          }

          & li a > div {
            display: flex;
            flex-direction: column;
          }

          & li a b {
            font-family: DBAdmanX, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 14px;
            color: #303133;
            text-decoration: underline $grey;
          }

          & li a span {
            font-family: DBAdmanX, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 1px;
            color: #9C9C9C;
          }
        }
      }

      .support h3, .rewards h3 {
        margin-bottom: 16px;
      }

      .support {
        display: flex;
        flex-direction: column;
        @media (max-width: 1440px) {
          margin-top: 64px;
        }

        &__list {
          display: flex;
          align-items: center;

          @include media(mobile) {
            text-align: center;
            row-gap: 20px;
            flex-wrap: wrap;
          }

          & img {
            margin-right: 20px;
          }

          @media (max-width: 1440px) {
            justify-content: center;
          }
        }

        &__element {

          @include media(mobile) {
            flex: 1 1 100px;
          }
        }
      }

      .rewards {
        margin-top: 40px;

        & a {

          @include media(mobile) {
            flex: 1 1 100px;
          }

          img {
            max-height: 32px;
          }
        }

        &__list {
          display: flex;
          align-items: center;
          gap: 20px;

          @media (max-width: 1440px) {
            justify-content: center;
          }

          @include media(mobile) {
            text-align: center;
            flex-wrap: wrap;
          }
        }
      }
    }

    .footer_lang {
      position: relative;
      z-index: 1;
      border-top: 1px solid #E7E7E7;
      padding: 20px 100px;
      display: flex;
      justify-content: center;
      width: 240px;
      margin: 52px auto 0 auto;
      @include media(landscape) {
        margin: 64px auto 0 auto;
      }
      @include media(tablet) {
        margin: 64px auto 0 auto;
      }
      @include media(mobile) {
        margin: 64px auto 0 auto;
      }

      & button {
        font-family: DBAdmanX, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #9C9C9C;
        text-decoration: none;
        height: 20px;
        padding: 0 12px;
        border: none;
        background: none;
        border-right: 1px solid #E7E7E7;
        cursor: pointer;
        text-transform: uppercase;

        &:last-of-type {
          border: none;
        }
      }

      & .select {
        color: #303133;
      }
    }
  }

  .copyright {
    margin: 0 auto;
    padding: 69px 0 5px 0;
    @include media(landscape) {
      padding: 40px 0 20px 0;
    }
    @include media(tablet) {
      padding: 40px 0 20px 0;
    }
    @include media(mobile) {
      font-size: 12px;
      line-height: 14px;
    }
  }

  .illustration {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
  }
}
