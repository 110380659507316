$container--mobile: 768px;
$container--tablet: 1024px;
$container--landscape: 1280px;
$container--desktop: 1440px;
$container--desktop-big: 1441px;

$mobile: 'only screen and (max-width: #{$container--mobile - 0.1})';
$tablet: 'only screen and (min-width: #{$container--mobile}) and (max-width: #{$container--landscape - 0.1})';
$landscape: 'only screen and (min-width: #{$container--landscape}) and (max-width: #{$container--desktop - 0.1})';
$desktop: 'only screen and (min-width: #{$container--desktop}) and (max-width: #{$container--desktop-big - 0.1})';
$desktop-big: 'only screen and (min-width: #{$container--desktop-big})';

@mixin media($type) {
  $media: $mobile;

  @if $type == mobile-small {
    $media: $mobile-small;
  } @else if $type == mobile {
    $media: $mobile;
  } @else if $type == tablet {
    $media: $tablet;
  } @else if $type == landscape {
    $media: $landscape;
  } @else if $type == desktop {
    $media: $desktop;
  } @else if $type == desktop-big {
    $media: $desktop-big;
  }

  @media #{$media} {
    @content;
  }
}

